"use strict";

(function () {
	function e(t, n, r) {
		function s(o, u) {
			if (!n[o]) {
				if (!t[o]) {
					var a = typeof require == "function" && require;if (!u && a) return a(o, !0);if (i) return i(o, !0);var f = new Error("Cannot find module '" + o + "'");throw f.code = "MODULE_NOT_FOUND", f;
				}var l = n[o] = { exports: {} };t[o][0].call(l.exports, function (e) {
					var n = t[o][1][e];return s(n ? n : e);
				}, l, l.exports, e, t, n, r);
			}return n[o].exports;
		}var i = typeof require == "function" && require;for (var o = 0; o < r.length; o++) {
			s(r[o]);
		}return s;
	}return e;
})()({ 1: [function (require, module, exports) {
		'use strict';

		var functions = require('./functions');

		var app = {
			init: function init() {
				// call your functions here
				functions.moblieBannerImage();
				functions.initSlider();
				functions.tabs();
				functions.tabsResult();
				functions.accordion();
				functions.dropdown();
				functions.hamburgerToggle();
				functions.subClick();
				functions.searchToggle();
				functions.navigation();
				functions.customSelect();
				functions.tableOverflowX();
				functions.tableSwipe();
				functions.sideMenu();
				functions.borderLink();
				functions.cookie();
				functions.paginationTop();
				functions.cookieModal();
				$('.people-block').each(function () {
					functions.equalHeights($('.person .person-info h4'));
				});
				$('.news-block').each(function () {
					functions.equalHeights($('.info-block .h3-smaller'));
				});
				functions.mailChimpHelper();
			}
		};

		$(function () {
			app.init();
			$(document).ready(function () {
				var hash = window.location.hash;
				if (hash && hash === '#newsletter') {
					!function (c, h, i, m, p) {
						m = c.createElement(h), p = c.getElementsByTagName(h)[0], m.async = 1, m.src = i, p.parentNode.insertBefore(m, p);
					}(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/81ffaf3b8096a6585ca94c401/8ba7133d49133f9946f915716.js");
					document.cookie = "MCPopupClosed=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
					document.cookie = "MCPopupSubscribed=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				}
			});
		});
	}, { "./functions": 2 }], 2: [function (require, module, exports) {
		'use strict';

		module.exports = {
			moblieBannerImage: function moblieBannerImage() {
				var banner = $('.js-banner-image');
				if (!banner.length) return;
				var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
				var bannerMobileImgUrl = banner.data('mobile-img');
				if (windowWidth < 768 && bannerMobileImgUrl.length > 5) {
					banner.css('background-image', 'url("' + bannerMobileImgUrl + '")');
				}
			},

			initSlider: function initSlider() {
				$('.slider').slick();
			},

			tabs: function tabs() {
				$('.tab-link').on('click', function () {
					var $btn = $(this);
					var $tabBox = $btn.closest('.tabs').find('.tab-box');
					if (!$btn.hasClass('active')) {
						$btn.siblings().removeClass('active');
						$btn.addClass('active');
						$tabBox.removeClass('active');
						$tabBox.eq($btn.index()).addClass('active');
					}
				});
			},

			tabsResult: function tabsResult() {
				$('.js-tab-link').on('click', function () {
					var $btn = $(this);
					var $tabBox = $btn.closest('.js-result-wrap').find('.js-tab-box');
					if (!$btn.hasClass('active')) {
						$btn.siblings().removeClass('active');
						$btn.addClass('active');
						$tabBox.removeClass('active-box');
						$tabBox.eq($btn.index()).addClass('active-box');
					}
				});
			},

			accordion: function accordion() {
				var _this = this;
				$('.accordion button').on('click', function () {
					if (!$(this).hasClass('open')) {
						$(this).addClass('open');
						$(this).next('.acc-text').stop().slideDown(600);
						_this.tableSwipeHelper($(this));
					} else {
						$(this).removeClass('open');
						$(this).next('.acc-text').stop().slideUp(600);
						_this.tableSwipeHelper($(this));
					}
				});
				// $('.accordion button').first().click();
			},

			dropdown: function dropdown() {
				$('.people-block .person').find('.inner').on('click', function (e) {
					var $person = $(this).parent();
					if (!$person.hasClass('details-opened')) {
						$('.people-block .person.details-opened').removeClass('details-opened').find('.item-dropdown').stop().slideUp();
						$person.addClass('details-opened').find('.item-dropdown').stop().slideDown(400);
					} else {
						$person.removeClass('details-opened');
						$person.find('.item-dropdown').stop().slideUp();
					}
				});

				$('.people-block .person').find('.item-dropdown .btn-close').on('click', function (e) {
					$(this).closest('.item-dropdown').slideUp();
					$('.people-block .person').removeClass('details-opened');
				});
			},

			customSelect: function customSelect() {
				$('.select-tabs-wrap .selected-item').on('click', function () {
					if (!$(this).hasClass('open')) {
						$(this).addClass('open');
						$(this).next('.js-tab').stop().slideDown();
					} else {
						$(this).removeClass('open');
						$(this).next('.js-tab').stop().slideUp();
					}
				});
				$('.select-tabs-wrap js-tab').on('click', function () {
					$(this).hide();
					$(this).siblings('.selected-item').removeClass('open');
					$(this).slideUp();
				});
				$('.select-tabs-wrap .js-tab li').on('click', function () {
					var width = $(window).width();
					var parent = $(this).parent();

					parent.siblings('.selected-item').find('.text').text($(this).find('a').text()).addClass('text-opacity');
					parent.siblings('.selected-item').removeClass('open');
					if (!$(this).parents('.select-tabs-wrap').hasClass('custom-select')) {
						parent.slideUp();
					} else if ($(this).parents('.select-tabs-wrap').hasClass('custom-select') && width <= 767) {
						parent.slideUp();
					}
				});
			},

			hamburgerToggle: function hamburgerToggle() {
				$('.hamburger').on('click', function () {
					$('.nav-primary').addClass('open');
					$('.header').addClass('open');
					$('body').addClass('no-scroll');
				});

				$('.close-btn').on('click', function () {
					$('.nav-primary').removeClass('open');
					$('.header').removeClass('open');
					$('body').removeClass('no-scroll');
					$('.sub-menu').removeClass('open');
				});
			},
			subClick: function subClick() {
				$('.js-form-sub').children().on('click', function () {
					$('.nav-primary').removeClass('open');
					$('.header').removeClass('open');
					$('body').removeClass('no-scroll');
					$('.sub-menu').removeClass('open');
				});
			},

			navigation: function navigation() {
				$('.nav-primary').find('a, button').on('click', function (e) {
					var $submenu = $(this).siblings('.sub-menu');
					if ($(window).width() < 1025) {
						if ($submenu.length) {
							$submenu.addClass('open');
							e.preventDefault();
						}
					}
				});

				// .link-back neka bude button
				$('.sub-menu').find('.btn-back').on('click', function () {
					var $submenu = $(this).closest('.sub-menu');
					$submenu.removeClass('open');
				});
			},

			borderLink: function borderLink() {
				var $btnDownload = $('.btn-download');
				var $btnLink = $('.btn-download');
				$btnDownload.each(function () {
					var text = $(this).text();
					$(this).html('<span>' + text + '</span>');
				});
				$btnLink.each(function () {
					var text = $(this).text();
					$(this).html('<span>' + text + '</span>');
				});
			},

			searchToggle: function searchToggle() {
				var form = $('.search-input');
				$('.search-btn').on('click', function () {
					form.addClass('open');
					form.removeClass('close');
					$('.search-input input').focus();
					$(this).addClass('search-open');
					// $(this).next().find('input').focusout(function() {
					// 	form.removeClass('open');
					// 	form.addClass('close');
					// 	$('.search-btn').removeClass('search-open');
					// });
				});

				$('.search-input input').blur(function () {
					form.removeClass('open');
					form.addClass('close');
					$('.search-btn').removeClass('search-open');
				});
			},

			sideMenu: function sideMenu() {
				$('.sidebar-primary-list li a').on('click', function (e) {
					$('.sidebar-secondary-list').not($(this).parent().children('.sidebar-secondary-list')).slideUp(300);
					if ($(this).parent().children('.sidebar-secondary-list').length) {
						$(this).next('.sidebar-secondary-list').stop().slideToggle(300);
						e.preventDefault();
					}
				});
			},

			tableOverflowX: function tableOverflowX() {
				var element = $('table');
				element.each(function () {
					$(this).wrapAll('<div></div');
					$(this).parent().css({
						'overflowX': 'auto',
						'marginBottom': 20
					});
				});
			},

			tableSwipe: function tableSwipe() {
				var $rteTable = $('.rte table');
				var visible = false;
				if ($rteTable.length) {
					$rteTable.each(function () {
						$(this).wrapAll('<div class="table-wrapper" />');
						if ($(this).width() > $(this).parent().width()) {
							$(this).parent().addClass('shadow');
							if (!visible) {
								$('<div class="table-swipe"><span class="font-ico-swipe-arrow"></span><span class="font-ico-swipe-hand"></span>Swipe table for more</div>').insertBefore($(this));
								$('<div class="table-swipe"><span class="font-ico-swipe-arrow"></span><span class="font-ico-swipe-hand"></span>Swipe table for more</div>').insertAfter($(this));
								$(this).prepend('<div class="table-inner" />');
							}
						} else {
							$(this).parent().parent().removeClass('shadow');
							if (visible) {
								$(this).unwrap();
							}
							$(this).parent().parent().find('.table-swipe').remove();
							visible = false;
						}
					});
				}
			},
			tableSwipeHelper: function tableSwipeHelper(button) {
				var table = button.parent().find('table');
				table.each(function () {
					var tableWidth = $(this).width();
					var parentWidth = $(this).parents('.rte').width();
					if (tableWidth > parentWidth) {
						if ($(window).width() > 703) {
							$(this).parent().removeClass('shadow');
							var swipe = $(this).parent().find('.table-swipe');
							swipe.each(function () {
								$(this).remove();
							});
						}
						return;
					} else {
						$(this).parent().parent().removeAttr('style');
						$(this).parent().removeClass('shadow');
						var swipe = $(this).parent().find('.table-swipe');
						swipe.each(function () {
							$(this).remove();
						});
					}
				});
			},
			cookie: function cookie() {
				$('.cookie').removeClass('cookie-closed');
				$('.close-cookie').on('click', function () {
					$('.cookie').addClass('cookie-closed');
				});
			},

			// equal heights
			equalHeights: function equalHeights(arrayItems, count) {
				if (arrayItems !== undefined && arrayItems.length > 0) {
					arrayItems.height('');

					var maxH = 0;

					if (count) {
						var arrays = [];
						while (arrayItems.length > 0) {
							arrays.push(arrayItems.splice(0, count));
						}

						for (var i = 0; i < arrays.length; i += 1) {
							var data = arrays[i];
							maxH = 0;
							for (var j = 0; j < data.length; j += 1) {
								var currentH = $(data[j]).outerHeight();
								if (currentH > maxH) {
									maxH = currentH;
								}
							}

							for (var k = 0; k < data.length; k += 1) {
								$(data[k]).css('height', maxH);
							}
						}
					} else {
						arrayItems.each(function () {
							var currentH2 = $(this).outerHeight();
							if (currentH2 > maxH) {
								maxH = currentH2;
							}
						});

						arrayItems.css('height', maxH);
					}
				}
			},

			paginationTop: function paginationTop() {
				$(document).on('click', '.pagination li a', function () {
					$('body, html').scrollTop(0);
				});
			},

			// redirect to email alerts page
			redirectToEmailAlerts: function redirectToEmailAlerts(linkElement, txtBoxSelector, controllerElement, emailElement) {
				var $link = $(linkElement);
				var controllerUrl = $(controllerElement).data('email-alerts-controller');
				var emailAddressEntered = '';
				$link.on('click', function (e) {
					e.preventDefault();
					emailAddressEntered = $(emailElement).val().trim();
					window.location.href = controllerUrl + '/?emailFromFooter=' + emailAddressEntered;
				});

				$(document).on("keypress", txtBoxSelector, function (e) {
					if (e.which === 13) {
						emailAddressEntered = $(emailElement).val().trim();
						window.location.href = controllerUrl + '/?emailFromFooter=' + emailAddressEntered;
					}
				});
			},

			mailChimpHelper: function mailChimpHelper() {
				$('.mailchimp-popup-button').on("click", function (e) {
					e.preventDefault();
					!function (c, h, i, m, p) {
						m = c.createElement(h), p = c.getElementsByTagName(h)[0], m.async = 1, m.src = i, p.parentNode.insertBefore(m, p);
					}(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/81ffaf3b8096a6585ca94c401/8ba7133d49133f9946f915716.js");

					unsetMailchimpCookies();
				});

				function unsetMailchimpCookies() {
					document.cookie = "MCPopupClosed=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
					document.cookie = "MCPopupSubscribed=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				}
			},
			cookieModal: function cookieModal() {
				var cookieBtn = document.querySelector('.js-cookie-opener');
				if (!cookieBtn) return;
				cookieBtn.addEventListener('click', function (e) {
					e.preventDefault();
					CookieControl.open();
				});
			}
		};
	}, {}] }, {}, [1]);